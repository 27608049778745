import React, { useContext, useState } from 'react';
import useWindowSize from '../hooks/useWindowSize.js';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tag from './Tag.js';
import { color } from '../assets/styles/css.js';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ProductContext from '../context/ProductContext';
import { Typography } from '@material-ui/core';
import { Link } from "react-tiger-transition";
import { getArticle } from '../api.js';
import noimage from './../assets/images/no-image.jpeg';
import LinesEllipsis from 'react-lines-ellipsis'


const [xmall, small, medium, big] = [600, 800, 1000, 1200]

const removeHTMLTags = (text) => {
    var stripedHtml = text.replace(/<[^>]+>/g, '');
    return stripedHtml
}

const CustomCard = ({ props, image, offer }) => {

    const windowSize = useWindowSize()
    const useStyles = makeStyles({
        root: {
            position: 'relative',
            maxWidth: 345,
            height: windowSize.width > small ? 230  : 200,
            boxShadow: 'none',
            paddingTop: 10,
        },
        root_image: {
            maxWidth: 400,
            boxShadow: 'none',
            paddingTop: 0
        },
        media: {
            maxHeight: 100,
            maxWidth: 300
        },
        btnHolder: {
            background:'#fff',
            position: 'absolute',
            bottom: 0,
            padding: 0,
            display: 'inline-flex',
            height: 40,
            width: '100%'
        },
        btn: {
            borderBottom: 0,
            borderRadius: 0,
            borderWidth: "1px",
            borderColor: "rgba(0,0,0,0.1)",
            textTransform: 'none',
            height: '100%',
            width: '100%',
            padding: 0,
            fontWeight: 'bold',
            fontSize: '7px',
            margin: 0,
            color: "#292929",
            lineHeight: "10px",
        },
        btnRight: {
            borderRight: 0,
            color: color.rosso
        },
        btnLeft: {
            marginRight: -8,
            borderLeft: 0,
            borderRight: 0
        },
        image: {
            height: 80,
            width: 80,
            alignSelf: 'center',
            justifySelf: 'center'
        },
        image_carta: {
            height: 120,
            width: 120,
            alignSelf: 'center',
            justifySelf: 'center'
        },
        margin: {
            marginTop: -20
        }
    });

    const classes = useStyles();
    const productContext = useContext(ProductContext);
    const [prod, setProd] = useState({})
    const { addItems } = productContext;
    React.useEffect(() => {
        const gettingProduct = async () => {
            await getArticle(props.id).then(res => {
                setProd(res)
            })
        }
        gettingProduct()
    }, [])

    return (
        <Card className={classes.root}>
            <CardContent style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                {offer && props?.tag_su &&
                    <div style={{ position: 'absolute', alignSelf: 'flex-end', top: 0 }}>
                        <Tag su tag={prod?.tag_su}></Tag>
                    </div>
                }
                <img alt={props?.Name} src={`${image}`} onError={(e) => e.target.src = noimage} className={classes.image}></img>
                <Typography variant="caption" style={{ fontSize: windowSize.width > medium ? '14px' : windowSize.width > small ? '12px' : '10px', color: "#292929", marginBottom: 0, marginLeft: props.prezzo_visibile && 5, marginRight: 5, marginTop: 0, paddingLeft: '10px', paddingRight:'10px', fontWeight: 'bold', lineHeight: 1}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: props.prezzo_visibile ? '1px #aaa solid' : 'none', borderRadius: 3, padding: 5 }}>
                        <span style={{fontSize: "clamp(9px, 2vw, 14px)", textAlign: 'left', letterSpacing: -.1, fontWeight: 600}}>
                            {props?.Name}
                        </span>
                        {props.prezzo_visibile &&
                            <span style={{textAlign: 'right'}}>
                                {props.Offerta != 0 ?
                                    <div style={{ wordWrap: 'break-word', display: 'inline-block', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        <span style={{ color: color.rosso, textDecoration: 'line-through', fontSize: windowSize.width > medium ? '12px' : windowSize.width > small ? '10px' : '8px', display: 'inline-block' }}>
                                            <span style={{color:color.nero}}>{props?.Prezzo_normale.toFixed(2).split('.')[0] + ','}</span>
                                            <span style={{ fontSize: windowSize.width > medium ? '12px' : windowSize.width > small ? '10px' : '8px', color:color.nero }}>{props?.Prezzo_normale.toFixed(2).split('.')[1]}€</span>
                                        </span>
                                        <span style={{ fontSize: windowSize.width > medium ? '14px' : windowSize.width > small ? '12px' : '10px', display: 'inline-block', marginLeft: 3 }}>
                                            {(props?.Prezzo_normale - props?.Prezzo_normale * props.Offerta / 100).toFixed(2).split('.')[0] + ','}
                                            <span style={{ fontSize: windowSize.width > medium ?  '12px' : windowSize.width > small ? '10px' : '7px',  }}>{(props?.Prezzo_normale - props?.Prezzo_normale * props.Offerta / 100).toFixed(2).split('.')[1]}€</span>
                                        </span>
                                    </div>
                                    :
                                    <span>
                                        <span style={{ fontSize: windowSize.width > medium ? '14px' : windowSize.width > small ? '12px' : '10px'  }}>{props?.Prezzo_normale.toFixed(2).split('.')[0] + ','}</span>
                                        <span style={{ fontSize: windowSize.width > medium ? '12px' : windowSize.width > small ? '10px' : '7px'  }}>{props?.Prezzo_normale.toFixed(2).split('.')[1]}€</span>
                                    </span>
                                }
                            </span>
                        }
                    </div>
                </Typography>
               

                {props?.Description_over &&
                   
                    <LinesEllipsis
                        style={{
                            fontSize: "clamp(8px, 2vw, 12px)",
                            // fontSize: windowSize.width > medium ? '10px' :  windowSize.width > small ?  '9px' : '8px',
                            color: color.grigio,
                            height: '30px',
                            marginLeft: 5,
                            marginRight: 10,
                            marginTop: 5,
                            paddingLeft: '10px',
                            textOverflow: 'ellipsis',
                            paddingRight: '10px'
                        }}
                        text={removeHTMLTags(props.Description_over)}
                        maxLine= '2'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                    />
                }
            </CardContent>
            <div style={{position:'absolute', bottom: 38, width:"100%", paddingLeft: '15px', paddingRight: '15px', marginTop: '5px'}}>
            {
                    prod?.tag_giu || props.canone_visibile ?
                    <Typography variant="caption" style={{ fontSize: '10px', color: "#292929", fontWeight: 'bold', lineHeight: 1 }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 3}}>
                            {offer && prod?.tag_giu ? <Tag tag={prod?.tag_giu}></Tag> : <div></div>}
                            <span style={{ fontSize: "clamp(7px, 1vw, 12px)", padding: 2, marginRight: 28 }}>{props.canone_visibile ? "CANONE " + props.prezzo_canone.toFixed(2).replace('.', ',') + "€/mese" : ''}</span>
                        {/* fontSize: windowSize.width > medium ? '9px' : windowSize.width > small ? '8px' : '7px' */}
                        </div>
                    </Typography>
                    : <div style={{marginBottom: 12, padding: 2}}></div>
            }
            </div>
            
            <CardActions className={classes.btnHolder}>
                <Button transition='glide-left' component={Link} to={`/product/${props.id}`} size="small" variant="outlined" className={[classes.btn, classes.btnLeft].join(" ")}>
                    <p style={{fontSize: "clamp(7px, 1vw, 12px)", textDecoration: 'none', color: color.grigio, width: '100%', height: '100%', textAlign: 'center' }}>Scopri di più</p>
                </Button>
                <Button variant="outlined" size="small" onClick={() => addItems(props)} className={[classes.btn, classes.btnRight].join(" ")}>
                <span style={{fontSize: "clamp(7px, 1vw, 12px)"}}>Richiedi un preventivo</span> 
                {/* style={{fontSize: windowSize.width > medium ? '12px' : windowSize.width > small ? '10px' : '7px'}} */}
                </Button>
            </CardActions>
        </Card >

    )
}

export default CustomCard