import React, { useContext } from 'react';
import ProductContext from '../context/ProductContext';
import ConfirmButton from '../components/ConfirmButton';

const Thanks = () => {
    const { resetProducts } = useContext(ProductContext);

    return (
        <div style={{textAlign:'center', margin: 'auto', maxWidth: '62vw'}}>
            <p style={{marginTop: 100}}>Grazie per l'interesse dimostrato nei confronti dei nostri prodotti! A breve sarai ricontattato da un nostro Esperto di Sicurezza.</p>
            <ConfirmButton directive url="/" functional={() => resetProducts()} text="Torna alla pagina principale!"></ConfirmButton>
        </div>
    )
}

export default Thanks
