import React, { useContext, useRef } from 'react'
// import useWindowSize from '../hooks/useWindowSize';
import banner from '../assets/images/placeholder-banner.png'
import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from '../components/SectionTitle';
import CustomCard from '../components/Card';
import { Container, Grid } from '@material-ui/core';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import BottomAppBar from '../components/BottomNavigation';
import { Typography } from '@material-ui/core';
import ProductContext from '../context/ProductContext';
import { getAllArticles, getAllCategories, getProductsFromCategory } from '../api.js';
import useModalRemoveProduct from '../hooks/useModalRemoveProduct';
import { color } from '../assets/styles/css.js';


const useStyles = makeStyles((theme) => ({

    container: {
        maxWidth: '1000px',
        ["@media (min-width:800px)"]: { 
            width: '60%',
            alignContent:'center'
        }
    },

    bannerHolder: {
        top: 45,
        zIndex: 1,
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor: "#eee",
        ["@media (min-width:800px)"]: { 
            width: '100%'
        }
    },
    banner: {
        width: '90vw',
        // aspectRatio: 1.75,
        ["@media (min-width:800px)"]: { 
            width: '100%',
            alignContent:'center'
        }
    },
    footerSpace: {
        marginBottom: 120
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        maxWidth: 400,
        width: "80%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 15,
        paddingBottom: 5
    },
    paper_ios: {
        maxWidth: 400,
        borderRadius: 10,
        width: "80%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        paddingTop: 10,
    },
    title_modal: {
        maxWidth: 250,
        margin: "10px auto 20px",
        textAlign: "center",
        fontSize: 15
    },
    container_buttons_mobile: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between"
    },
    container_buttons_mobile_ios: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        borderTop: "2px solid #F1F1F1"
    },
    btn_decline_ios: {
        width: "100%",
        borderRight: "1px solid #F1F1F1",
        padding: "11px 0",
        fontSize: 12,
        color: color.grigio
    },
    btn_remove_ios: {
        width: "100%",
        borderLeft: "1px solid #F1F1F1",
        padding: "11px 0",
        fontSize: 12,
        color: color.rosso
    },
    btn_decline: {
        fontSize: 12,
        color: color.grigio
    },
    btn_remove: {
        fontSize: 12,
        color: color.rosso
    }

}));


const Homepage = () => {

    const { openModalRemoveProduct } = useModalRemoveProduct()
    const rootRef = useRef()
    const classes = useStyles();
    // const windowSize = useWindowSize()
    const productContext = useContext(ProductContext);
    const [Categories, setCategories] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const { Products, Articles, setArticles } = productContext;

    React.useEffect(() => {
        const gettingData = async () => {
            const response = await getAllArticles();
            setArticles(response);
        }
        gettingData();

        const gettingCategories = async () => {
            const response = await getAllCategories();

            const p = {};
            for (const i of response) {
                const prods = await getProductsFromCategory({ id: i.id }).then(
                    res => { return res }
                )
                Object.assign(p, prods)
            }
            setCategories(response)
            setLoading(false)
        }
        gettingCategories();
    }, [])

    return (
        <Container
        className={[classes.container]}
            style={{
                marginTop: 45,
            }}
        >
            <div className={classes.bannerHolder}>
                <img className={classes.banner} alt={"Catalogue Banner"} src={banner}></img>
            </div>

            <div ref={rootRef} style={Products.length ? { marginBottom: 200 } : { marginBottom: 80 }}>

                {
                    loading ? <Loader
                    type="Puff"
                    color={color.rosso}
                    height={40}
                    width={40}
                    style={{textAlign: 'center', marginTop: '100px'}}
                /> :
                    Categories.sort((catA, catB) => catA.Ordinamento - catB.Ordinamento).map(categoria => {
                        return (
                            <React.Fragment key={categoria.id}>
                                <SectionTitle text={categoria.Title}></SectionTitle>
                                <Grid container spacing={2}>
                                    {
                                        categoria.categoria_prodottis.filter(cat => cat != null).length == 0 ?
                                            <Typography variant="caption" style={{
                                                fontSize: '10px',
                                                marginLeft: 10,
                                                color: color.grigio,
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                <p>Nessun prodotto trovato in questa categoria.</p>
                                            </Typography>

                                            :
                                            categoria.categoria_prodottis.filter(cat => cat != null).sort((prodA, prodB) => prodA.Ordinamento - prodB.Ordinamento).map((article, index) => {
                                                const prod = Articles.filter(art => art.id == article.product)[0]
                                                if (prod) {
                                                    return (
                                                        <Grid item xs={6} md={6} lg={6} xl={4} key={prod.id}>
                                                            <CustomCard offer={true} props={prod} image={prod.Image?.url}></CustomCard>
                                                        </Grid>
                                                    )
                                                }
                                            })
                                    }
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }

                <div className={classes.footerSpace}></div>
                
                {Products.length ? <BottomAppBar openModalRemoveProduct={openModalRemoveProduct}></BottomAppBar> : <></>}
            </div>
        </Container>
    )
}

export default Homepage
