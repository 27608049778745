import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-tiger-transition";
import { Button, Container } from '@material-ui/core';
import SubNavigation from '../components/SubNavigation';
import { makeStyles } from '@material-ui/core/styles';
import ProductContext from '../context/ProductContext';
import useModalRemoveProduct from '../hooks/useModalRemoveProduct';
import { color } from '../assets/styles/css.js';
import noimage from './../assets/images/no-image.jpeg';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 200,
        marginTop: 45,
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
    },
    prezzoTag: {
        backgroundColor: color.rosso,
        position: 'absolute',
        top: 100,
        right: '22.5%',
        marginTop: 0,
        marginBottom: 0,
        padding: 5,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 15,
        display: 'flex',
        flexDirection: 'column',
        ["@media (max-width:800px)"]: {
            right: 20
        }
    },
    bottomNav: {
        width: '100vw',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        fontSize: 12,
        backgroundColor: '#fff',
        boxShadow: "1px 1px 3px 3px #ccc",
    },
    itemHolder: {
        padding: "10px 0",
        height: 30,
        width: '100vw',
        display: 'flex',
        ["@media (min-width:800px)"]: {
            justifyContent: 'center',
        },
        flexDirection: 'row',
        overflowX: 'scroll',
        overflowY: 'hidden',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        marginLeft: 30,
    },
    btnHolder: {
        padding: "10px 0",
        display: 'inline-flex',
        height: 30,
        ["@media (min-width:800px)"]: {
            width: '40%',
        },
        width: '90vw',
    },
    btn: {
        borderRadius: 5,
        borderWidth: 1.5,
        textTransform: 'none',
        height: '100%',
        width: '100%',
        padding: 0,
        fontSize: 10,
        margin: 0,
    },
    btnRight: {
        borderLeft: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        color: color.rosso
    },
    btnLeft: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    },
    fontRed: {
        color: color.rosso
    },
    buttonLabel: {
        color: color.nero,
        textDecoration: 'none',
        width: '100%',
        height: '100%',
        fontSize: "clamp(11px, 1vw, 14px)"
    },
    image: {
        height: 200,
        width: 200,
        marginTop: 35,
        marginBottom: 10
    },
    description: {
        ["@media (max-width:800px)"]: {
            width: '85vw'
        },
        width: '54vw',
        backgroundColor: 'white',
        padding: 10,
        paddingTop: 0,
        borderRadius: 10,
        overflow: 'none'
    }

}));



const Product = () => {

    const { openModalRemoveProduct } = useModalRemoveProduct();

    const classes = useStyles();
    const productContext = useContext(ProductContext);
    const { Products, Articles, addItems, removeItem } = productContext;
    const [Product, setProduct] = useState();
    let { id } = useParams();

    useEffect(() => {
        const fetchedItem = Articles.filter((article => { if (article.id == Number(id)) { return article } }));
        setProduct(fetchedItem[0]);
    }, [Products])

    const button = <div className={classes.btnHolder}>
        <Button size="small" variant="outlined" className={[classes.btn, classes.btnLeft].join(' ')}>
            <Link to="/" transition="glide-right" className={classes.buttonLabel}>Torna agli altri dispositivi</Link>
        </Button>
        {Products.some(prod => prod.id == Number(id)) ?
            <Button variant="outlined" size="small" className={[classes.btn, classes.btnRight].join(' ')}>
                <Link to="/preventivo" className={classes.buttonLabel}>Conferma un preventivo</Link>
            </Button> :
            <Button onClick={() => addItems(Product)} variant="outlined" size="small" className={[classes.btn, classes.btnRight].join(' ')}>
                <span className={classes.buttonLabel} style={{color:color.rosso}}>Richiedi un preventivo</span>
            </Button>
        }
    </div>


    return (
        <>
            <SubNavigation text={Product ? Product.Name : "Product"}></SubNavigation>
            <Container>
                <div className={classes.content}>
                    {Product?.prezzo_visibile || Product?.canone_visibile ?
                        <div className={classes.prezzoTag}>
                            {Product.prezzo_visibile &&
                                <span style={{ color: '#fff', fontSize: 8, textTransform: 'uppercase', fontWeight: 'bold', letterSpacing: -.1 }}>Prezzo
                                    {
                                        Product.Offerta == 0 ?
                                            <span style={{ color: color.nero, fontSize: '12px', fontWeight: 'bold', letterSpacing: -.1 }}> {Product?.Prezzo_normale.toFixed(2).split('.')[0]},<span style={{ fontSize: '10px' }}>{Product.Prezzo_normale.toFixed(2).split('.')[1]}€</span></span>
                                            :
                                            <span style={{ color: color.nero, fontSize: '12px', fontWeight: 'bold', letterSpacing: -.1 }}> {(Product?.Prezzo_normale - (Product?.Prezzo_normale * Product?.Offerta / 100)).toFixed(2).split('.')[0]},<span style={{ fontSize: '10px' }}>{(Product?.Prezzo_normale - (Product?.Prezzo_normale * Product?.Offerta / 100)).toFixed(2).split('.')[1]}€</span></span>
                                    }

                                </span>}
                            {Product.canone_visibile && <span style={{ color: '#fff', fontSize: "clamp(12px, 2vw, 14px)", paddingRight: '7.5px', paddingLeft: '7.5px', textTransform: 'uppercase', fontWeight: 'bold', letterSpacing: -.1 }}>Canone <span style={{ color: color.nero, textTransform: 'lowercase' }}>{Product?.prezzo_canone.toFixed(2).replace('.', ',')}€/mese</span></span>}
                        </div>
                        : <></>
                    }
                    <img src={Product ? `${Product?.Image?.url}` : ""} onError={(e) => e.target.src = noimage} className={classes.image} ></img>
                    <div className={classes.description}>
                        <h5 style={{ color: color.nero, letterSpacing: -.1 }}>{Product ? Product.Name : "Product name"}</h5>
                        <p style={{ fontSize: "14px", color: color.nero }}><ReactMarkdown rehypePlugins={[rehypeRaw]} children={Product && Product.Description_over}></ReactMarkdown></p>
                        <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
                            {Product ? Product.product_galleries.sort((pA, pB) => pA.Ordinamento - pB.Ordinamento).map(img =>
                                <img key={img.id}
                                    height="275"
                                    style={{ aspectRatio: 1 }} src={`${img?.image?.url}`} onError={(e) => e.target.src = noimage}></img>
                            ) : ""}
                        </div>
                        <p style={{ fontSize: 14, color: color.nero }}><ReactMarkdown rehypePlugins={[rehypeRaw]} children={Product && Product.Description_under}></ReactMarkdown></p>
                    </div>
                </div>
            </Container>

            <div className={classes.bottomNav}>
                {Products.length ?
                    <>
                        <div style={{ position: 'relative', top: 10 }}>
                            <h5 style={{ textAlign: 'center', fontSize: "clamp(12px, 2vw, 14px)", paddingTop: 0, marginTop: 0, marginBottom: 0, color: color.nero }}>Stai già richiedendo un preventivo per:</h5>
                            <div className={classes.itemHolder}>
                                {Products?.slice(0).reverse().map((prod, i) =>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 15 }} key={prod.id} onClick={() => openModalRemoveProduct(prod.id)}>
                                        <img height="20" width="20" style={{ borderColor: color.grigio, marginRight: 5, borderStyle: 'solid', borderWidth: 1 }} src={`${prod?.Image?.url}`} onError={(e) => e.target.src = noimage} />
                                        <h6 style={{
                                            whiteSpace: 'nowrap', 
                                            display: 'flex',
                                            color: color.grigio,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            fontSize: "clamp(10px, 2vw, 14px)",
                                            marginRight: i+1 == Products.length ? 25 : 0
                                        }}>{prod.Nome_Sintetico}</h6>
                                        {i + 1 !== Products.length && <div style={{ height: 20, width: 2, marginLeft: 15, backgroundColor: color.rosso }}></div>}
                                    </div>
                                )}
                            </div>
                        </div>
                        {button}
                    </>
                    : button}
            </div>

        </>
    )
}
export default Product
