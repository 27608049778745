import React from 'react'
import { Link } from "react-tiger-transition";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: 50,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'left',
        justifyContent: 'center',
        marginBottom: 80,
    }
}));


const ConfirmButton = ({ url, text, directive, disabled, functional }) => {
    const classes = useStyles();

    if (directive) {
        if (functional) {
            return (
                <Link transition={"glide-left"} to={url} style={{ textDecoration: 'none' }}>
                    <Button onClick={functional} disabled={disabled} type="submit" variant="contained" style={{ textTransform: 'none', backgroundColor: '#ff0033', color: 'white', fontSize: 13, boxShadow: 'none', borderRadius: 50 }} className={classes.confirmButton}>
                        {text}
                    </Button>
                </Link>
            )
        }
        return (
            <Link transition={"glide-left"} to={url} style={{ textDecoration: 'none' }}>
                <Button disabled={disabled} type="submit" variant="contained" style={{ textTransform: 'none', backgroundColor: '#ff0033', color: 'white', fontSize: 10, boxShadow: 'none' }} className={classes.confirmButton}>
                    <span style={{fontSize: "clamp(10px, 2vw, 12px)"}}>{text}</span>
                </Button>
            </Link>
        )
    }

    return (
        <div style={{ textDecoration: 'none' }}>
            <Button type="submit" disabled={disabled} variant="contained" style={{ textTransform: 'none', backgroundColor: '#ff0033', color: 'white', fontSize: 12, boxShadow: 'none',lineHeight:"16px",margin: "20px 0", opacity: disabled ? 0.6 : 1 }} className={classes.confirmButton}>
                {text}
            </Button>
        </div>
    )
}

export default ConfirmButton
