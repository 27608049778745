import axios from 'axios'

export const getAllArticles = () => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/Products?pubblicato=true`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}

export const getAllCategories = () => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/Categorias?pubblicato=true`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}

export const getProductByCategory = (info) => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/Products?pubblicato=true&categorias_contains=${info}`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}

export const getCategoryWithProducts = (info) => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/categoryWithProducts`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}

export const getProductsFromCategory = (info) => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/categoria-prodottis?categoria=${info.id}`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}

export const getArticle = (id) => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/Products/${id}`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}

export const postPreventivo = async (data) => {
    var response;
    try {
        response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Preventivos`, JSON.stringify(data), 
        { headers: { 'Content-Type': 'application/json' } })
            .then(async res => {
                var objList = [];
                var success;
                for (let [key, value] of Object.entries(data.Order)) {
                    const obj = {
                        product: key,
                        nome: data.ExtraInfo.filter(ex => ex.id == key)[0].Name,
                        preventivo: res.data.id,
                        prezzo: data.ExtraInfo.filter(ex => ex.id == key)[0].Prezzo_normale,
                        canone: data.ExtraInfo.filter(ex => ex.id == key)[0].prezzo_canone,
                        sconto: data.ExtraInfo.filter(ex => ex.id == key)[0].Offerta, 
                        prezzo_visibile: data.ExtraInfo.filter(ex => ex.id == key)[0].prezzo_visibile,
                        canone_visibile: data.ExtraInfo.filter(ex => ex.id == key)[0].canone_visibile, 
                        immagine: data.ExtraInfo.filter(ex => ex.id == key)[0].Image.url, 
                        quantita: value,
                    }
                    success = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ordinis`, JSON.stringify(obj),
                     { headers: { 'Content-Type': 'application/json' } })
                     objList.push(obj)
                }
                if(success.status == 200) {
                    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sendemail`, {"email": data.Email, "name": data.Nome, "telefono": data.Telefono, "preferenza": data.Preferenza, "date": new Date().toLocaleString(), objList: objList, order:0})
                }
                return success;
            })
    } catch (err) {
        console.error("Error found: ", err);
    }
    return response;
}

export const getScripts = () => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/Scripts`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}

export const getCookies = () => {
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/Cookies`, {
        method: "GET",
    }
    ).then((res) => {
        return res.json()
    }).then((data) => {
        return data
    })
    return response;
}