import React from 'react';
import { Link } from "react-tiger-transition";
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { color } from '../assets/styles/css.js';

const useStyles = makeStyles((theme) => ({
    nav: {
        position: 'fixed',
        top: 45,
        zIndex:10,
        backgroundColor: '#ccc',
        display:'inline-flex',
        left:0,
        right:0,
        ["@media (min-width:800px)"]: { 
            width: '56vw',
            alignContent:'center',
            left: '22%'
        },
        width: '100vw',
        height: 40,
        alignItems:'center',
        justifyContent:'space-between',
    },
    placeholder: {
        width: 10,
        marginTop: 3
    }, 
    font: {
        fontWeight: 'bold',
        color: color.nero
    },
    icon: {
        color:color.rosso, 
        marginLeft: 15,
        height: '30px',
        ["@media (min-width:800px)"]: { 
            height: '20px',
        }
    }
}));

const SubNavigation = ({text}) => {
    const classes = useStyles();
    
    return (
        <div className={classes.nav}>
            <div className={classes.placeholder}>
                <Link to="/" transition={"glide-right"}>
                    <ArrowBackIosIcon className={classes.icon}></ArrowBackIosIcon>
                </Link>
            </div>
            <p className={classes.font}>{text}</p>
            <div className={classes.placeholder}></div>
        </div>
    )
}

export default SubNavigation
