import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ConfirmButton from './ConfirmButton';
import ProductContext from '../context/ProductContext';
import { color } from '../assets/styles/css.js';
import noimage from './../assets/images/no-image.jpeg';

const useStyles = makeStyles((theme) => ({

  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    height: 120,
    backgroundColor: "#fff",
    color: color.nero,
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  subTitle: {
    textAlign: 'center',
    marginTop: 2,
    fontSize: "clamp(12px, 2vw, 14px)",
    marginBottom: -8
  },
  contentHolder: {
    height: 60,
    display: 'flex',
    width: '100vw',
    marginBottom: 5,
    flexDirection: 'row',
    ["@media (min-width:800px)"]: {
      justifyContent:'center',
      paddingLeft:0
    },
    paddingLeft: 20,
    justifyItems: 'center',
    overflowY: 'hidden',
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  },
  productItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  confirmButton: {
    backgroundColor: 'red',
    textTransform: 'none',
    fontSize: 8,
    height: 30,
    margin: 0,
    color: '#fff',
  }
}));

export default function BottomAppBar({ openModalRemoveProduct }) {
  const classes = useStyles();
  const productContext = useContext(ProductContext);
  const { Products } = productContext;

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.row}>
          <div style={{ position: 'relative', top: 10 }}>
            <h6 className={classes.subTitle}>Stai richiedendo un preventivo per:</h6>
            <div className={classes.contentHolder}>
              {Products?.slice(0).reverse().map((Product, i) =>
                <div className={classes.productItem} key={Product.id}
                  onClick={() => {
                    openModalRemoveProduct(Product.id)
                  }}>
                  <img alt={Product.Nome} style={{ borderColor: color.grigio, marginRight: 5, borderStyle: 'solid', borderWidth: 1 }} height="20" width="20" src={`${Product?.Image?.url}`} onError={(e) => e.target.src = noimage} />
                  <h6 style={{ color: color.grigio, fontSize: "clamp(10px, 2vw, 12px)", whiteSpace: 'nowrap', marginRight: i+1 == Products.length ? 15 : 0}}>{Product.Nome_Sintetico}</h6>
                  {i + 1 !== Products.length && <div style={{ height: 20, width: 2, marginLeft: 15, backgroundColor: color.rosso }}></div>}
                </div>
              )}
            </div>
          </div>
          <ConfirmButton url="/preventivo" directive text={"Conferma il preventivo"}></ConfirmButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
