import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import image from '../assets/images/logo-verisure.png';

const useStyles = makeStyles((theme) => ({
    header: {
        left: '50%',
        marginRight: '-50%',
        marginTop: "20px",
        transform: 'translate(-50%, -50%)',
        ["@media (min-width:800px)"]: { 
            width: '57vw',
            maxWidth: '1000px',
            right: '22%'
        }
    },
    root: {
        flexGrow: 1,
        // display:'block',
        backgroundColor: '#eee',
        position:'relative'
        // marginBottom: 45,
    },
    backgroundColor: {
        backgroundColor: '#eee',
        boxShadow: 'none',
        height: 45
    },
    menuRight: {
        backgroundColor: '#db022a',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        height: 38,
        paddingRight: 25,
        paddingLeft: 15,
        position: 'absolute',
        right: 0,
        top: 4,
        fontSize: 10
    },
    marginBtm: {
        marginTop: 5
    },
    logo: {
        marginLeft: -10,
        marginTop: -15,
        height: 75,
        width: 115
    }
}));

const Header = () => {
    const classes = useStyles();
    return (
        <header className={classes.root}>
            <AppBar className={[classes.backgroundColor, classes.header].join(' ')}>
                <Toolbar>
                    <img className={classes.logo} alt="Verisure logo" src={image}></img>
                    <div className={classes.menuRight}>
                        <p className={classes.marginBtm}>Catalogo dedicato<br />ai Clienti Verisure</p>
                    </div>
                </Toolbar>
            </AppBar>
        </header>
    )
}

export default Header