import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { color } from '../assets/styles/css.js';
import useWindowSize from '../hooks/useWindowSize.js';

const useStyles = makeStyles({
    tagStyle: {
        marginBottom: 3,
        marginTop: 3,
        marginRight: 5,
        borderRadius: 5,
        fontWeight: 'bold',
        fontSize: 7
    }
});

const Tag = ( {tag, su} ) => {
    const classes = useStyles();
    const windowSize = useWindowSize()
    const sfondo = tag?.colore_sfondo == 'rosso' ? color.rosso : tag?.colore_sfondo == 'blue' ? 'blue' : tag?.colore_sfondo == 'nero' ? color.nero : tag?.colore_sfondo == 'verde' ? "green" : tag?.colore_sfondo == 'giallo' ? "yellow" : tag?.colore_sfondo
    const fontColore = tag?.colore == 'rosso' ? color.rosso : tag?.colore == 'blue' ? 'blue' : tag?.colore == 'nero' ? color.nero : tag?.colore == 'verde' ? "green" : tag?.colore == 'giallo' ? "yellow" : tag?.colore
    const fontSize = "clamp(7px, 1vw, 12px)" 
// windowSize.width > 1000 ? '9px' : windowSize.width > 800 ? '8px' : '7px'
    return (
        <span className={classes.tagStyle}
            style={{
                padding: su && 2,
                backgroundColor: sfondo,
                color: fontColore,
                fontSize: fontSize
            }}
        >{tag?.nome}</span>
    )
}

export default Tag