import React from 'react';
import Header from './components/Header.js';
import { BrowserRouter as Router } from 'react-router-dom';
import Homepage from './views/Homepage.js';
import Product from './views/Product.js';
import Preventivo from './views/Preventivo.js';
import ProductContext from './context/ProductContext.js';
import { ModalProvider } from './hooks/useModalRemoveProduct.js';
import Thanks from './views/Thanks.js';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Div100vh from 'react-div-100vh'
import "react-tiger-transition/styles/main.min.css";
import { Navigation, Route, glide } from "react-tiger-transition";


glide({
  name: 'glide-left',
  duration: 400,
});

glide({
  name: 'glide-right',
  direction: 'right',
  duration: 400,
});

function App() {
  const [Products, setProducts] = React.useState([]);
  const [Articles, setArticles] = React.useState([]);
  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Fira Sans", sans-serif`,
    }
  });

  const addItems = (object) => {
    if (!(Products.some(prod => prod.id === object.id)))
      setProducts([...Products, object])
  }

  const removeItem = (id) => {
    const updatedProducts = Products.filter(Product => Product.id !== id);
    setProducts(updatedProducts);
  }

  const resetProducts = () => {
    setProducts([]);
  }

  const productContext = {
    Products,
    Articles,
    setArticles,
    addItems,
    removeItem,
    resetProducts
  }


  return (
    <Div100vh>
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <ModalProvider removeItem={removeItem}>
            <Header></Header>
            <ProductContext.Provider value={productContext}>
              <Router>
                <Navigation>
                  <Route screen exact path="/">
                    <Homepage />
                  </Route>
                  <Route screen exact path="/product/:id">
                    <Product />
                  </Route>
                  <Route screen exact path="/preventivo">
                    <Preventivo />
                  </Route>
                  <Route screen exact path="/done">
                    <Thanks />
                  </Route>
                  {/* <Route>
                  <NotFound />
                </Route> */}
                </Navigation>
              </Router>
            </ProductContext.Provider>
          </ModalProvider>
        </React.Fragment>
      </MuiThemeProvider>
    </Div100vh>
  );
}

export default App;
