import React, { useContext, useState } from 'react';
import { Container, IconButton } from '@material-ui/core';
import SubNavigation from '../components/SubNavigation';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../components/SectionTitle';
import { uuid } from 'uuidv4';
import ProductContext from '../context/ProductContext';
import ConfirmButton from '../components/ConfirmButton';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm } from 'react-hook-form';
import { postPreventivo } from '../api.js';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import ReCAPTCHA from 'react-google-recaptcha';
import { Add, Delete, Remove } from '@material-ui/icons';
import useModalRemoveProduct from '../hooks/useModalRemoveProduct';
import noimage from './../assets/images/no-image.jpeg';
import { color } from '../assets/styles/css.js';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: 50,
        marginTop: 40,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'left',
        justifyContent: 'center',
        marginBottom: 80,
    },
    container: {
        ["@media (min-width:800px)"]: { 
            width: '59%',
            alignContent:'left'
        }
    },
    label: {
        color: color.grigio,
        marginBottom: 2,
        marginLeft: 2,
        fontSize: "clamp(12px, 2vw, 14px)"
    },
    input: {
        marginLeft: 2,
        width: '65%',
        ["@media (min-width:800px)"]: { 
            width: '80%',
        },
        borderWidth: 1,
        borderColor: color.grigio,
        borderStyle: 'solid',
        borderRadius: 3,
        height: 20,
        outlineOffset: 0,
        outlineWidth: 0,
        outlineStyle: 0
    },
    dangerText: {
        color: color.rosso,
        fontSize: 10
    },
    input_number: {
        width: 30,
        height: 30,
        padding: 0,
        textAlign: "center"
    },
    icon_btn: {
        padding: "15px 8px"
    }
}));

const Preventivo = () => {
    const classes = useStyles();
    const history = useHistory();

    const { openModalRemoveProduct } = useModalRemoveProduct()

    const [qtyProducts, setQtyProducts] = useState({})
    const [prezzoTotale, setPrezzoTotale] = useState(0);
    const [consent, setConsent] = useState(false);
    const [checked, setChecked] = useState(false);
    const [canoneTotale, setCanoneTotale] = useState(0);
    const [loading, setLoading] = useState(false);
    const productContext = useContext(ProductContext);
    const { Products } = productContext;
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const reRef = React.useRef();

    const dangerInput = {
        borderBottomWidth: 2,
        borderBottomColor: color.rosso
    }

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    React.useEffect(() => {
        if (Products.length === 0) {
            history.push('/');
        } else {
            var cookie = getCookie("consent");
            setConsent(cookie ? true : false);
            Products.filter(prod => prod.prezzo_visibile).map(prod => {
                if (prod.Offerta != 0) {
                    if (qtyProducts[prod.id]) {
                        setPrezzoTotale(prezzoTotale + (prod.Prezzo_normale - prod.Prezzo_normale * prod.Offerta / 100) * qtyProducts[prod.id])
                    } else {
                        setPrezzoTotale(prezzoTotale + (prod.Prezzo_normale - prod.Prezzo_normale * prod.Offerta / 100))
                    }
                } else setPrezzoTotale(prezzoTotale + (prod.Prezzo_normale))
            })
            Products.filter(prod => prod.canone_visibile).map(prod => {
                if (qtyProducts[prod.id])
                    setCanoneTotale(canoneTotale + (prod.prezzo_canone * qtyProducts[prod.id]))
                else setCanoneTotale(canoneTotale + prod.prezzo_canone)
            })
        }
    }, [Products, qtyProducts])


    const onSubmit = async (d) => {
        trigger();
        setLoading(true)
        d.PreventivoID = uuid();
        d.Date = new Date().toISOString();
        d.Token = await reRef.current.executeAsync();
        d.Products = Products.map(product => product.id);
        d.ExtraInfo = Products;
        d.Order = {}
        Object.assign(d.Order, qtyProducts)

        Object.entries(d.Order).forEach(([key, value]) => {
            if (!d.Products.includes(key)) {
                delete d.Order[key];
            }
        });
        for (const prds in d.Products) {
            d.Order[d.Products[prds]] = qtyProducts[d.Products[prds]] > 1 && qtyProducts[d.Products[prds]] !== undefined ? qtyProducts[d.Products[prds]] : 1
        }
        reRef.current.reset();
        var answer = await postPreventivo(d);
        setLoading(false)
        if (answer.status === 200) {
            history.push('/done');
        }
    }

    const Prezzo = (prodotto) => {
        var price = prodotto.Offerta != 0 ? ((prodotto.Prezzo_normale - (prodotto.Prezzo_normale * prodotto.Offerta / 100)).toFixed(2)) : prodotto.Prezzo_normale.toFixed(2);
        return <span style={{ color: color.nero, fontSize: "clamp(11px, 2vw, 11px)" }}>{price.split('.')[0]},<span style={{ fontSize: "clamp(9px, 2vw, 10px)" }}>{price.split('.')[1]}€</span></span>
    }

    const Canone = (prodotto) => {
        return <span style={{ color: color.nero, fontSize: "clamp(11px, 2vw, 11px)" }}>{prodotto.prezzo_canone.toFixed(2).split('.')[0]},<span style={{ fontSize: "clamp(9px, 2vw, 10px)" }}>{prodotto.prezzo_canone.toFixed(2).split('.')[1]}€/mese</span></span>
    }

    const TotalPrezzo = ({ products }) => (
        <span style={{ fontSize: "clamp(12px, 2vw, 14px)" }}>
            TOTALE PREZZO:&nbsp;
            <span style={{ fontSize: "clamp(12px, 2vw, 14px)" }}>
                {products.filter(prod => prod.prezzo_visibile).reduce((sum, i) => (
                    qtyProducts[i.id] ? i.Offerta == 0 ? sum += i.Prezzo_normale * qtyProducts[i.id] : sum += (i.Prezzo_normale - (i.Prezzo_normale * i.Offerta / 100)) * qtyProducts[i.id]
                        : i.Offerta == 0 ? sum += i.Prezzo_normale : sum += (i.Prezzo_normale - (i.Prezzo_normale * i.Offerta / 100))
                ), 0).toFixed(2).replace('.', ',').split(',')[0]}</span>,
            {products.filter(prod => prod.prezzo_visibile).reduce((sum, i) => (
                qtyProducts[i.id] ? i.Offerta == 0 ? sum += i.Prezzo_normale * qtyProducts[i.id] : sum += (i.Prezzo_normale - (i.Prezzo_normale * i.Offerta / 100)) * qtyProducts[i.id]
                    : i.Offerta == 0 ? sum += i.Prezzo_normale : sum += (i.Prezzo_normale - (i.Prezzo_normale * i.Offerta / 100))
            ), 0).toFixed(2).replace('.', ',').split(',')[1]}€
        </span>
    )

    const TotalCanone = ({ canone }) => (
        <span style={{ fontSize: "clamp(12px, 2vw, 14px)", whiteSpace: 'nowrap' }}>
            TOTALE CANONE MENSILE:&nbsp;
            <span style={{ fontSize: "clamp(12px, 2vw, 14px)" }}>
                {canone.filter(can => can.canone_visibile).reduce((sum, i) => (
                    qtyProducts[i.id] ? sum += i.prezzo_canone * qtyProducts[i.id] : sum += i.prezzo_canone
                ), 0).toFixed(2).replace('.', ',').split(',')[0]}</span>,
            {canone.filter(can => can.canone_visibile).reduce((sum, i) => (
                qtyProducts[i.id] ? sum += i.prezzo_canone * qtyProducts[i.id] : sum += i.prezzo_canone
            ), 0).toFixed(2).replace('.', ',').split(',')[1]}€
        </span>
    )

    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setQtyProducts({ ...qtyProducts, [name]: value })
    }

    const handleClickQuantity = (name, add) => {
        const value = qtyProducts[name] || 1;
        if (add) {
            setQtyProducts({ ...qtyProducts, [name]: value + 1 })
        } else {
            setQtyProducts({ ...qtyProducts, [name]: value - 1 })
        }
    }

    return (
        <>
            <SubNavigation text="Conferma il preventivo"></SubNavigation>
            <Container className={classes.container}>
                <div className={classes.content}>
                    <SectionTitle text="Verrai ricontattato per un preventivo con un'offerta vantaggiosa sul costo di installazione dei seguenti dispositivi"></SectionTitle>
                    <div style={{ width: '95%', backgroundColor: 'white', padding: 10, borderRadius: 10, marginBottom: 10, alignSelf: 'center', paddingBottom: 0 }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tr style={{ borderBottomColor: color.nero, borderBottomWidth: 1, borderBottomStyle: 'solid' }}>
                                <td>Prodotto</td>
                                <td style={{ paddingLeft: 12 }}>Quantità</td>
                                <td></td>
                            </tr>
                            <tr style={{ height: 20 }}></tr>
                            {Products?.map((Product) => {
                                return (
                                    <tr>
                                        <td key={Product.id} style={{ display: 'flex', flexDirection: 'row', marginTop: 7 }}>
                                            <img style={{ objectFit: "contain", height: 40, width: 40, borderWidth: 1, borderStyle: 'solid', borderColor: "#aaa", marginRight: 10 }} src={`${Product.Image?.url}`} onError={(e) => e.target.src = noimage}></img>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start', lineHeight: '10px' }}>
                                                <h6 style={{ fontSize: "clamp(12px, 2vw, 14px)", color: color.nero, marginBottom: 2, marginTop: 0 }}>{Product.Name}</h6>
                                                {Product.prezzo_visibile && <h6 style={{ fontSize: "clamp(8px, 2vw, 10px)", color: color.nero, marginBottom: -1, marginTop: 0, color: color.rosso }}>PREZZO {Prezzo(Product)}</h6>}
                                                {Product.canone_visibile && <h6 style={{ fontSize: "clamp(8px, 2vw, 10px)", color: color.nero, marginBottom: 0, marginTop: 0, color: color.rosso }}>CANONE {Canone(Product)}</h6>}
                                            </div>
                                        </td>

                                        <td style={{ justifyContent: "center", alignItems: "center" }}>
                                            <IconButton className={classes.icon_btn} onClick={() => handleClickQuantity(`${Product.id}`)}>
                                                <Remove style={{ fontSize: '9px', marginRight: -5 }} />
                                            </IconButton>

                                            <input className={classes.input_number} min={1} onChange={handleChange} name={`${Product.id}`} value={qtyProducts[`${Product.id}`] || 1} type="number" />

                                            <IconButton className={classes.icon_btn} onClick={() => handleClickQuantity(`${Product.id}`, true)}>
                                                <Add style={{ fontSize: '9px', marginLeft: -5 }} />
                                            </IconButton>
                                        </td>
                                        <td onClick={() => openModalRemoveProduct(Product.id)}>
                                            <IconButton className={classes.icon_btn}>
                                                <Delete />
                                            </IconButton>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                            <tr style={{ height: 20 }}></tr>
                            {Products.some((val) => val.prezzo_visibile == true) | Products.some((val) => val.canone_visibile == true) ?
                                <tfoot>
                                    <tr style={{ borderTopColor: color.nero, borderTopWidth: 1, borderTopStyle: 'solid' }}>
                                        <td colSpan={3}>
                                            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                                                {!Products.every((val) => val.prezzo_visibile == false) ? <span style={{ textAlign: 'center' }}><TotalPrezzo products={Products} /></span> : <></>}
                                                {!Products.every((val) => val.canone_visibile == false) ? <span style={{ marginRight: 15 }}></span> : <></>}
                                                {!Products.every((val) => val.canone_visibile == false) ? <span style={{ textAlign: 'center' }}><TotalCanone canone={Products} /></span> : <></>}
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                                : <></>
                            }
                        </table>
                    </div>
                    <SectionTitle text="Inserisci i tuoi dati e conferma la richiesta"></SectionTitle>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '95%', backgroundColor: 'white', padding: 10, borderRadius: 10, alignSelf: 'center', paddingTop: 0 }}>
                        {consent && <ReCAPTCHA sitekey="6LdI_6IaAAAAADFqzpbnMFU34Wli584-1rzPq5CK" size="invisible" ref={reRef} />}
                        <h6 className={classes.label}>Nome e Cognome</h6>
                        <input className={classes.input} style={errors.Nome ? dangerInput : null} type="text" {...register("Nome", { required: true, minLength: 5, maxLength: 80 })} />
                        {errors.Nome && <p className={classes.dangerText}>&#9; Questo campo è obbligatorio!</p>}

                        <h6 className={classes.label}>Telefono</h6>
                        <input className={classes.input} style={errors.Telefono ? dangerInput : null} type="tel" {...register("Telefono", { required: true, minLength: 6, maxLength: 11, pattern: /^[0-9]+$/ })} />
                        {errors.Telefono?.type == "required" && <p className={classes.dangerText}>&#9; Questo campo è obbligatorio!</p>}
                        {(errors.Telefono?.type == "pattern" || errors.Telefono?.type == "minLength" || errors.Telefono?.type == "maxLength") && <p className={classes.dangerText}>&#9; Inserire un numero di telefono valido, e.g.: +393491234567</p>}

                        <h6 className={classes.label}>E-mail</h6>
                        <input className={classes.input} style={errors.Email ? dangerInput : null} type="email" {...register("Email", { required: true, pattern: '/^+@+$/i' })} />
                        {errors.Email && <p className={classes.dangerText}>&#9; Questo campo è obbligatorio!</p>}

                        <h6 className={classes.label}>Preferenza orario di ricontatto:</h6>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: -20 }}>
                            <div>
                                <input {...register("Preferenza", { required: true })} type="radio" value={true} />
                                <label style={{ fontSize: "clamp(10px, 2vw, 12px)", position: 'relative', bottom: '2px', fontWeight:'bold', color: "#555" }}> Mattina (9:00 - 13:00)</label>
                            </div>
                            <div>
                                <input {...register("Preferenza", { required: true })} type="radio" value={false} />
                                <label style={{ fontSize:  "clamp(10px, 2vw, 12px)", position: 'relative', bottom: '2px', fontWeight:'bold', color: "#555" }}> Pomeriggio (15:00 - 20:00)</label>
                            </div>
                            <div></div>
                        </div><br />
                        {errors.Preferenza ? <small className={classes.dangerText}>&#9; Questo campo è obbligatorio! </small> : ""}

                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: 9, marginTop: 10 }}>
                            <Checkbox
                                checked={checked}
                                onChange={e => {
                                    setChecked(e.target.checked);
                                }}
                                color="default"
                                size="small"
                                style={{ fontSize: '10px', marginLeft: -8, bottom: 1, marginRight: -5, alignContent: 'center', border: 'none' }}
                            />
                            <label style={{ fontSize: "clamp(10px, 2vw, 12px)", color: color.grigio, textAlign: 'justify', alignSelf:'center', width: '85%' }}>Presto il consenso alla memorizzazione e al trattamento dei dati personali sopra indicati.</label>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                                loading ?
                                    <Loader
                                        type="Puff"
                                        color={color.rosso}
                                        height={40}
                                        width={40}
                                    />
                                    :
                                    <ConfirmButton disabled={!consent || !checked} directive={false} text={"Conferma la richiesta di ricontatto per un preventivo"}></ConfirmButton>}
                        </div>
                    </form>
                </div>
            </Container>
        </>
    )
}
export default Preventivo
