import React, { createContext, useContext, useState} from 'react'
import { Modal, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        maxWidth:400,
        width: "80%",
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid red',
        boxShadow: theme.shadows[5],
        padding: 15,
        paddingBottom: 5
    },
    paper_ios: {
        maxWidth:400,
        borderRadius: 10,
        width: "80%",
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid red',
        boxShadow: theme.shadows[5],
        paddingTop: 10,
    },
    title_modal:{
        maxWidth:250,
        margin:"10px auto 20px",
        textAlign:"center",
        fontSize: 12,
        color:"#292929",
        fontSize: "clamp(12px, 2vw, 16px)"
    },
    container_buttons_mobile:{
        display:"flex",
        width:"100%",
        justifyContent:"space-between"
    },
    container_buttons_mobile_ios:{
        display:"flex",
        width:"100%",
        justifyContent:"space-between",
        borderTop:"2px solid #F1F1F1"
    },
    btn_decline_ios:{
        width: "100%",
        borderRight:"1px solid #F1F1F1",
        padding:"11px 0",
        fontSize: "clamp(8px, 2vw, 12px)",
        color:"#06c"
    },
    btn_remove_ios:{
        width: "100%",
        borderLeft:"1px solid #F1F1F1",
        padding:"11px 0",
        fontSize: "clamp(8px, 2vw, 12px)",
        color:"#ff0033"
    },
    btn_decline:{
        fontSize: "clamp(8px, 2vw, 12px)",
        color:"#06c"
    },
    btn_remove:{
        fontSize: "clamp(8px, 2vw, 12px)",
        color:"#ff0033"
    }
}));

const isMobile = {
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }
};

const ModalContext = createContext({
    'open': false
});

export function ModalProvider({children, removeItem}){
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [id, setIdProduct] = useState(null)
    

    return (
        <ModalContext.Provider value={{setOpen,setIdProduct}}>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={open}
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
            >
                <div className={!isMobile.iOS() ? classes.paper : classes.paper_ios}>
                    <h4 className={classes.title_modal} id="server-modal-title">Rimuovere il prodotto selezionato dal preventivo?</h4>
                    {/* <p id="server-modal-description">If you disable JavaScript, you will still see me.</p> */}
                    <div className={!isMobile.iOS() ? classes.container_buttons_mobile : classes.container_buttons_mobile_ios}>
                        <Button className={!isMobile.iOS() ? classes.btn_decline : classes.btn_decline_ios} onClick={() => { setOpen(false) }}>Annulla</Button>
                        <Button className={!isMobile.iOS() ? classes.btn_remove : classes.btn_remove_ios} onClick={() => { removeItem(id); setOpen(false) }}>Rimuovi</Button>
                    </div>
                </div>
            </Modal>
            {children}
        </ModalContext.Provider>
    )
}

export default function useModalRemoveProduct(){
    const { setOpen, setIdProduct } = useContext(ModalContext)


    ///OPEN MODAL
    const openModalRemoveProduct = (id) => { 
        setIdProduct(id)
        setOpen(true)
    }

    //EXPORT HOOK
    return{
        openModalRemoveProduct
    }
}