import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    line: {
        backgroundColor:"#dedede",
        color:"#292929",
        height: 2,
        border: 'none',
        marginTop: -15,
        marginBottom: 15
    },
    font: {
        fontWeight: 600,
        letterSpacing: -.1,
        fontSize: '14px',
        fontSize: "clamp(12px, 2vw, 16px)"
    }
}));

const SectionTitle = ({text}) => {
    const classes = useStyles();
    return (
        <div>
            <h5 className={classes.font}>{text}</h5>
            <hr className={classes.line}></hr>
        </div>
    )
}

export default SectionTitle
